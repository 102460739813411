// Core
import React from "react"
import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { Link } from "gatsby"

// Styles
import "swiper/swiper.scss"
import "swiper/components/navigation/navigation.scss"

// Modules
SwiperCore.use([Navigation])

const Slider = () => {
  const breakpoints = useBreakpoint()

  return (
    <Swiper spaceBetween={30} slidesPerView={breakpoints.md ? 1 : 2} navigation>
      <SwiperSlide className="slide">
        <p className="slide__text">
          With Outloud, front-line teams are able to provide much better service
          to guests given the Outloud tech creates clear incentives to do so.
          Guests are happy, restaurants get higher revenue, and employees are
          directly rewarded as a result of their hard work.
        </p>
        <div className="slide__wrapper">
          <p className="slide__client">Tanvir, VP of tech</p>
          <p className="slide__locations">
            <span className="slide__locations--count">400+</span> stores
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide className="slide">
        <p className="slide__text">
          You can't manage what you can't measure. Outloud quantifies every
          single interaction between customers and restaurant teams to help run
          better ops.
        </p>
        <div className="slide__wrapper">
          <p className="slide__client">Daniel, CFO</p>
          <p className="slide__locations">
            <span className="slide__locations--count">3,000+</span> stores
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide className="slide">
        <p className="slide__text">
          Outloud.ai reduced operational alerts by 80%, increasing efficiency
          and profitability for midwest Popeyes stores
        </p>
        <div className="slide__wrapper">
          <Link to="/popeyes" className="slide__link">
            Read more
          </Link>
        </div>
      </SwiperSlide>
      <SwiperSlide className="slide">
        <p className="slide__text">
          Outloud.ai help Burger King operator in the Northeast to increase
          upsell revenue by 380%
        </p>
        <div className="slide__wrapper">
          <Link to="/burger_king" className="slide__link">
            Read more
          </Link>
        </div>
      </SwiperSlide>
    </Swiper>
  )
}

export default Slider
