// Core
import React from "react"
import PropTypes from "prop-types"
import { GatsbyProvider } from "../context/context"

// Styles
import "../styles/main.scss"

const EmptyLayout = ({ children }) => {
  return (
    <GatsbyProvider>
      <div className="site-content">
        <main className="empty-main">{children}</main>
      </div>
    </GatsbyProvider>
  )
}

EmptyLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default EmptyLayout
